import { Accordion } from '@local/web-design-system/dist/components/Accordion';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { FolderIcon } from '@local/web-design-system/dist/icons/Files/FolderIcon';
import Grid from '@mui/material/Grid';
import { useContext, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store/store';
import { getObjectTreeExpandedById, getTreeItemById } from 'src/store/visualization/selectors';
import { expandTreeItem, collapseTreeItem } from 'src/store/visualization/visualizationSlice';
import { ROOT_TREE_ID, ID_PARAM } from 'src/strings';
import { useBuildObjectTree } from 'src/visualization/context/hooks/useBuildObjectTree';
import { useColormapLoader } from 'src/visualization/context/hooks/useColormapLoader';
import { useDrag } from 'src/visualization/context/hooks/useDragAndDrop';

import { GooseListContext } from '../../context/gooseContext/gooseContext';
import { ObjectListItemControl } from './ObjectListItemControl/ObjectListItemControl';
import { useStyles } from './ProjectTreePanel.styles';
import type { ProjectTreeProps } from './ProjectTreePanel.types';

export function SkeletonObjectsPanelContents() {
    return (
        <Grid>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

export function ProjectTreePanel() {
    const { classes } = useStyles();
    const { data } = useContext(GooseListContext);
    useBuildObjectTree(data);
    useColormapLoader();

    const treeItem = useSelector(getTreeItemById(ROOT_TREE_ID));
    return useMemo(
        () => (
            <Grid item xs className={classes.contentsContainer}>
                {treeItem?.children?.map((childItemId: string) => (
                    <ProjectTree key={childItemId} treeItemId={childItemId} data={data} />
                ))}
            </Grid>
        ),
        [treeItem?.children],
    );
}

function ProjectTree({ treeItemId, data }: ProjectTreeProps) {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const { onDragStart } = useDrag(treeItemId);
    const isExpanded = useSelector(getObjectTreeExpandedById(treeItemId));

    const [searchParams] = useSearchParams();
    const objectIdQueryParam = searchParams.get(ID_PARAM) ?? '';
    const treeItem = useSelector(getTreeItemById(treeItemId));
    useEffect(() => {
        if (objectIdQueryParam === treeItemId && treeItem) {
            dispatch(expandTreeItem(treeItem.treeId));
        }
    }, []);

    if (treeItem === undefined) return null;
    if (treeItem.children) {
        return (
            <Grid container className={classes.folderContainer}>
                <Grid item xs={12}>
                    <Accordion
                        mountOnEnter
                        icon={<FolderIcon fontSize="small" />}
                        title={treeItem.name}
                        expanded={isExpanded}
                        draggableProps={{
                            draggable: true,
                            onDragStart,
                        }}
                        onChange={(_, toExpand) => {
                            if (toExpand) {
                                dispatch(expandTreeItem(treeItem.treeId));
                            } else {
                                dispatch(collapseTreeItem(treeItem.treeId));
                            }
                        }}
                    >
                        {treeItem.children.map((childItemId) => (
                            <ProjectTree key={childItemId} treeItemId={childItemId} data={data} />
                        ))}
                    </Accordion>
                </Grid>
            </Grid>
        );
    }

    return <ObjectListItemControl key={treeItem.treeId} {...treeItem} />;
}
