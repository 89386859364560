import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import { WorkspaceSearch } from '@local/workspaces/src/pages/workspaceListingPage/WorkspaceSearch';
import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';

import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { ENTER_OBJECT_NAME } from 'src/strings';

import { useStyles } from './RecycleBinListingControls.styles';

export const RecycleBinListingControls = ({ children }: { children?: ReactNode }) => {
    const { classes } = useStyles();

    const { search, setSearch } = useObjectSearchParams();

    return (
        <Grid className={classes.optionsContainer}>
            {children}

            <Grid item xs container justifyContent="right" alignItems="center">
                <Grid
                    container
                    alignItems="center"
                    flexWrap="nowrap"
                    className={classes.searchFilterContainer}
                >
                    <WorkspaceSearch
                        setSearchTerm={setSearch}
                        searchPlaceHolderValue={ENTER_OBJECT_NAME}
                        userAction={UserActionWorkspace.WORKSPACE_OBJECTS_SEARCH}
                        userActionCategory={CategoriesWorkspace.WORKSPACE_OBJECTS_PAGE}
                        defaultValue={search}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
