import { User } from '@local/login';
import {
    DateTimeCell,
    StringCell,
} from '@local/web-design-system/dist/components/GenericListing/ListCells';
import { FieldDefinition } from '@local/web-design-system/dist/components/GenericListing/types';
import { theme } from '@local/web-design-system/dist/styles';
import { defaultStringSort, timestampSort, userSort } from '@local/workspaces/dist/utils/Sorting';

import {
    OBJECT_HEADER_NAME,
    OBJECT_HEADER_MODIFIED_BY,
    OBJECT_HEADER_LAST_MODIFIED,
    OBJECT_HEADER_DATA_TYPE,
    OBJECT_HEADER_WORKSPACE,
} from 'src/strings';

import { EmptyCell, FileCell, FolderCell } from './fieldCells';
import { SchemaCell } from './SchemaCell';
import { UserCell } from './UserCell';

export const folderRowDefinition: FieldDefinition[] = [
    {
        key: 'name',
        label: 'Name',
        component: FolderCell,
        styles: {
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
    {
        key: 'name2',
        label: 'Name',
        component: EmptyCell,
    } as FieldDefinition<string>,
    {
        key: 'name3',
        label: 'Name',
        component: EmptyCell,
    } as FieldDefinition<string>,
    {
        key: 'name4',
        label: 'Name',
        component: EmptyCell,
    } as FieldDefinition<string>,
];

export const objectDefinition: FieldDefinition[] = [
    {
        key: 'name',
        label: OBJECT_HEADER_NAME,
        component: FileCell,
        sortFunction: defaultStringSort,
        styles: {
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
    {
        key: 'schema',
        label: OBJECT_HEADER_DATA_TYPE,
        component: SchemaCell,
        sortFunction: defaultStringSort,
        styles: {
            width: '15%',
            textTransform: 'capitalize',
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
    {
        key: 'created_by',
        label: OBJECT_HEADER_MODIFIED_BY,
        component: UserCell,
        sortFunction: userSort,
        styles: {
            width: '15%',
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<User>,
    {
        key: 'created_at',
        label: OBJECT_HEADER_LAST_MODIFIED,
        component: DateTimeCell,
        sortFunction: timestampSort,
        styles: {
            width: '15%',
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
];

export const RecycledObjectDefinition: FieldDefinition[] = [
    {
        key: 'name',
        label: OBJECT_HEADER_NAME,
        component: FileCell,
        sortFunction: defaultStringSort,
        styles: {
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
    {
        key: 'schema',
        label: OBJECT_HEADER_DATA_TYPE,
        component: SchemaCell,
        sortFunction: defaultStringSort,
        styles: {
            width: '15%',
            textTransform: 'capitalize',
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
    {
        key: 'workspace_name',
        label: OBJECT_HEADER_WORKSPACE,
        component: StringCell,
        sortFunction: defaultStringSort,
        styles: {
            width: '15%',
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
    {
        key: 'created_at',
        label: OBJECT_HEADER_LAST_MODIFIED,
        component: DateTimeCell,
        sortFunction: timestampSort,
        styles: {
            width: '15%',
            color: theme.palette.primary.dark,
        },
    } as FieldDefinition<string>,
];
